<template>
  <div class="section-content pt-4" :class="{'mt-10' : $vuetify.breakpoint.xs , 'mt-n3' : $vuetify.breakpoint.sm}">
    <div class="primary-section-content1">
      <div class="test-conatainer">
        <hb-header full>
          <hb-page-header
            title="Unified Communications"
            description="This is the main Center for Communication at your property."
          >
          </hb-page-header>
        </hb-header>
        <div style="display: flex;">
          <div :style="threadStyle" class="content-view py-0">
              <v-row>
                <v-col
                  cols="4"
                  xs="6"
                  sm="6"
                  md="5"
                  lg="5"
                  xl="5"
                  class="py-0 px-0"
                  style="max-width:250px;"
                >
                  <tenant-leads-list 
                    @showInteraction="showInteractionDetails" 
                    :updatedInteractor="updatedInteractor"
                  ></tenant-leads-list>
                </v-col>
                <v-col
                  cols="8"
                  xs="6"
                  sm="6"
                  md="7"
                  lg="7"
                  xl="7"
                  class="py-0 px-0 chat-window"
                  style="min-width: calc(100% - 250px);"
                >
                  <chat-window 
                    v-if="(chatObj !== null && chatObj !== undefined)"
                    @viewContact="showSideBlade"
                    :InteractionObj="chatObj"
                    chatWindowHeight="medium"
                    @interactorUpdate="updateInteractor"
                  ></chat-window>
                </v-col>
              </v-row>
          </div>
          <div v-if="showAside" class="side-blade">
            <v-row class="mt-3 mr-2" justify="end">
              <hb-btn icon tooltip="Close" @click="showAside = false" active-state-off>mdi-close</hb-btn>
            </v-row>
            <v-row>
             <!-- <contact-view :show="false" :contact_id="contactId"></contact-view> -->
            </v-row>
          </div>
        </div>
      </div>
    </div>
    <v-navigation-drawer
      absolute
      v-model="showDrawer"
      right
      hide-overlay
      height="77%"
      width="344"
      style="top:185px;"
    >
      <v-row class="mt-3 mr-2" justify="end">
        <hb-btn icon tooltip="Close" @click="showDrawer = false" active-state-off>
          mdi-close
        </hb-btn>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script type="text/babel">

import Header from '../header/Index.vue';
import api from '../../assets/api.js';
import TenantAndLeadsList from './TenantAndLeadsList';
import ChatWindow from './ChatWindow.vue';
import ContactView from '../contacts/ContactView'
import { EventBus } from '../../EventBus.js';

export default {
  data () {
    return {
      contactTab: undefined,
      contact: {
        salutation: '',
        first: 'Adriana C.',
        middle: 'Ocampo',
        last: 'Uria',
        suffix: '',
        status: 'Current',
        email: 'roland.schertenleib@gmail.com',
        Phones: [
          { phone:'(714) 681-0323'}
        ]
      },
      menuOptions:[
        { label: 'All', key: 1 },
        { label: 'Email', key: 2 },
        { label: 'SMS', key: 3 },
        { label: 'Phone', key: 4 },
        { label: 'Notes', key: 5 },
      ],
      showAside: false,
      showDrawer: false,
      slideBladeStyle: '',
      contactId: '',
      chatObj: undefined,
      contactInfo:undefined,
      updatedInteractor: undefined,
      re_render: false,
      threadStyle:{
        height: '100%',
      },
    }
  },
  components:{
    'tenant-leads-list': TenantAndLeadsList,
    'chat-window': ChatWindow,
    'contact-view': ContactView
  },
  created () {
    this.setChatDimensions()
    // window.addEventListener('resize', _.debounce(() => {
    //   this.setChatDimensions();
    // }, 350));
  },
  methods:{
    showSideBlade (contact_Id) {
      this.contactId = contact_Id
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.showAside = !this.showAside
      } else{
        this.showDrawer = !this.showDrawer
      }
    },
    async updateInteractor(obj) {
      let response = await api.get(this, api.CONTACTS + obj.contact_id + '/interaction-details');
      
      this.updatedInteractor = response.interactor;
    },
    showInteractionDetails(obj) {
      this.chatObj = obj
      this.myId = obj.id
    },
    
    getWindowSize(){
      var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
      return {x,y}
    },
    setChatDimensions() {
      var windowSize = this.getWindowSize()
      var height = 0;
      if(windowSize.x <= 767) {
        height = windowSize.y - 368;
      } else {
        height = windowSize.y - 195;
      }
      this.threadStyle.height = height + 'px';
    }
  }
} 

</script>

<style scoped lang="scss">

.subHeading {
  font-size: 14px;
  vertical-align: top;
  letter-spacing: -0.2px;
  align-content: left;
  margin: 0px;
}

.section-content {
  padding-right: 0px !important;
}

.side-blade {
  width: 30%;
  border: 1px solid #e2e2e2;
  background-color: #fff;
  box-shadow: 1px 1px 4px 0px #dee5e7;
}
.content-view {
  padding-right: 12px;
  padding-left: 12px;
  box-shadow: none !important;
}
.chat-window{
  border-color: rgba(11, 18, 29, 0.1);
  border-style: solid;
  border-width: 0px 1px 0px 1px;
}
</style>
