<template>
    <div class="main-section-heading">
        <h1 class="main-section-header">
            {{ Title }}
            <span :v-if="Subtitle" class="subheading">{{ Subtitle }}</span>
        </h1>
        <h6 class="main-section-header"></h6>
        <div class="title-section-settings">
            <slot />
        </div>
    </div>
</template>
<script >
export default {
    data() {
        return {

        }
    },
    computed:{
        Title(){
            return this.$props.title || 'None';
        },
        Subtitle(){
            return this.$props.subtitle;
        }
    },
    props: ['title', 'subtitle']
}
</script>
<style lang="scss">

.main-section-heading {
    margin: 0px -20px 0px 0px;
    padding: 20px 20px 20px 0;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    display: flex;

    &.no-border{
       border-bottom: none;
       margin-bottom:10px;
    }

    .main-section-header {
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        margin: 0;
        line-height: 32px;
        span {
            &.subheading {
                display: block;
                font-size: 16px;
                line-height: 16px;
                color: #263238
            }
        }
    }
}

@media (max-width: 479px) {
  .main-section-header {
    margin: 0px 0px 5px;
  }
}
</style>
